import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Selecto, { OnSelectEnd } from 'react-selecto';
import styled, { createGlobalStyle } from 'styled-components';
import ReactFlow, {
  ReactFlowInstance,
  ConnectionMode,
  Node,
} from 'react-flow-renderer';
import { toPng } from 'html-to-image';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router';
import ReactPDF, {
  Document,
  Page,
  StyleSheet,
  View,
  Image as PDFImage,
} from '@react-pdf/renderer';
import xmljs from 'xml-js';
import queryKeys from '../../../constants/queryKeys';
import { DiagramControl } from '../../molecules/DiagramControl';
import { DiagramToolbar } from '../../molecules/DiagramToolbar';
import { MemberList } from '../../organisms/MemberList';
import { Toast } from '../../atoms/Toast';
import { Props as INotification } from '../../atoms/Toast/Component';
import { useGlobalState } from '../../../hooks/global';
import { useBoardHooks } from '../../../hooks/board';
import { useSocketHooks } from '../../../hooks/socket';
import { GameCardDrawer } from '../../molecules/GameCardDrawer';
import { ChatBox } from '../../organisms/ChatBox';
import { Board, BoardCustomers } from '../../../domain/entities/board';
import { customNodeTypes, customEdgeTypes } from './components';
import { DropAreaData, ShapeData } from './types';
import { Header } from '../../molecules/Header';
import { BOARD_CODE_IF_NEW } from '../../pages/BoardEditor/Component';
import {
  useNodesUtils,
  useAreaNodes,
  useGroupNodes,
} from './components/hooks/nodes';
import { areCollinear } from '../../../utils/planes';
import { includeWhitelistedNodes } from '../../../utils/arrays';
import DiagramCursors from './components/common/DiagramCursors';

export const LINE_NODE_Z_INDEX = 5;

export const DIAGRAM_CONTAINER_ID = 'diagram-editor-container';

const GlobalStyle = createGlobalStyle`
  .fontsize-6 {
    ::marker {
      font-size: 6px;
    }
  }
  .fontsize-7 {
    ::marker {
      font-size: 7px;
    }
  }
  .fontsize-8 {
    ::marker {
      font-size: 8px;
    }
  }
  .fontsize-10 {
    ::marker {
      font-size: 10px;
    }
  }
  .fontsize-11 {
    ::marker {
      font-size: 11px;
    }
  }
  .fontsize-12 {
    ::marker {
      font-size: 12px;
    }
  }
  .fontsize-14 {
    ::marker {
      font-size: 14px;
    }
  }
  .fontsize-18 {
    ::marker {
      font-size: 18px;
    }
  }
  .fontsize-24 {
    ::marker {
      font-size: 24px;
    }
  }
  .fontsize-30 {
    ::marker {
      font-size: 30px;
    }
  }
  .fontsize-36 {
    ::marker {
      font-size: 36px;
    }
  }
  .fontsize-48 {
    ::marker {
      font-size: 48px;
    }
  }
  .fontsize-60 {
    ::marker {
      font-size: 60px;
    }
  }
  .fontsize-72 {
    ::marker {
      font-size: 72px;
    }
  }
  .fontsize-96 {
      ::marker {
        font-size: 96px;
    }
  }
`;

const Wrapper = styled.div`
  height: 100%;
  outline: none;
  .react-flow .react-flow__edges {
    /* NOTE: !important appears to be the only way to override edge inline style problem with react flow */
    z-index: -1 !important;
  }
  .react-flow__nodes .selected {
    /* NOTE: !important appears to be the only way to override node inline style problem with react flow */
    z-index: ${LINE_NODE_Z_INDEX + 1} !important;
  }
  postion: relative;
  .moveable-origin {
    display: none;
  }
  .moveable-control-box {
    z-index: 0;
  }
`;

const StyledReactFlow = styled(ReactFlow)`
  cursor: default;
  &.disable-nodes {
    cursor: grab;
    .react-flow__node {
      /* NOTE: !important is necessary here because pointer-events is set as inline style by react-flow-renderer */
      pointer-events: none !important;
    }
  }
`;

const StyledDiagramControl = styled(DiagramControl)`
  bottom: 8px;
  left: 14px;
`;

const DiagramToolbarContainer = styled.div`
  bottom: 15px;
  position: absolute;
  z-index: 5;
  max-width: 361.64px;
  left: calc((100vw - 361.64px) / 2);
`;

const DiagramMembersContainer = styled.div`
  position: absolute;
  z-index: 5;
  display: flex;
  justify-content: center;
  height: calc(100% - 100px);
  left: 0;
  top: 14px;
  padding-left: 12px;
`;

const StyledHeader = styled(Header)``;

export type Props = {
  className?: string;
  boardData?: Board | undefined;
  currentBoardCustomer?: BoardCustomers;
  isFetchingCurrentBoardCustomer?: boolean;
  boardCode?: string;
};

const Component = ({
  className,
  boardData,
  boardCode,
  currentBoardCustomer,
  isFetchingCurrentBoardCustomer,
}: Props): React.ReactElement => {
  const queryClient = useQueryClient();
  const [allowElementsSelect, setAllowElementsSelect] = useState<boolean>(true);
  const [isMediaPickerOpen, setIsMediaPickerOpen] = useState<boolean>(false);
  // NOTE: needed for handling multi select movement from lasso
  const [didSelectFromLasso, setDidSelectFromLasso] = useState<boolean>(false);
  const [notification, setNotification] = useState<INotification | null>(null);
  const [isCursorOnChatBox, setCursorOnChatBox] = useState<boolean>(false);
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [isChatBoxOpen, setChatBoxOpen] = useState<boolean>(false);
  const {
    useActions,
    useBoardDiagram,
    useClipboard,
    useMySelectedNodes,
    useBoardSocket,
    useBoardAPI,
    nodeState,
    edgeState,
    customersNodeIdsState,
    activeCustomerIdsState = {
      activeCustomerIds: [],
    },
  } = useBoardHooks();
  const {
    recentlySelectedNodeIds,
    setRecentlySelectedNodeIds,
  } = useMySelectedNodes;
  const { activeCustomerIds } = activeCustomerIdsState;
  const {
    sendCursorPosition,
    joinBoard,
    forceDiagramUpdate,
    sendSelectedNodeIds,
    sendLatestDiagramRequest,
  } = useBoardSocket();
  const { setCopiedNodes } = useClipboard;
  const { useSocket } = useSocketHooks();
  const { fetchBoardDiagram } = useBoardDiagram();
  const { fetchBoardCustomer, saveBoard, fetchBoardCustomers } = useBoardAPI();
  const {
    useCurrentCards: { setActiveCard, setSocketCard, setCurrentFlippedCards },
    useCurrentUser,
  } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const customerId = currentUser?.user?.customer?.id;
  const {
    removeLineNodeGroup,
    removeLineNodePoint,
    removeNodes,
    removeParentNodes,
    setParentNode,
    getOverlappingNode,
    findNode,
    addNewNode,
    handlePasteNodes,
  } = useNodesUtils();
  const { onDragNodesEnd, onDragNodesStart } = useGroupNodes();
  const {
    myCanvasId,
    myDropAreaId,
    setMyCanvasNode,
    removeMyAreaNodes,
    setMyDropAreaNode,
  } = useAreaNodes();
  const { nodes, onNodesChange, setNodes, clearLocalNodes } = nodeState;
  const { edges, setEdges, onEdgesChange, clearLocalEdges } = edgeState;
  const { customersSelectedNodeIds } = customersNodeIdsState;
  const navigate = useNavigate();

  const { socketInit, disconnectSocket, socket } = useSocket;
  const { redo, undo, clearActions } = useActions;
  const flowRef = useRef<ReactFlowInstance | undefined>(undefined);
  const [isBlocking, setIsBlocking] = useState<boolean>(false);
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const prevActiveCustomerIdsRef = useRef<number[]>([]);
  const currentlySelectedNodes = useMemo(() => {
    const actuallySelectedNodes = nodes.filter(
      node => node.selected && recentlySelectedNodeIds.includes(node.id),
    );
    return actuallySelectedNodes;
  }, [nodes, recentlySelectedNodeIds]);

  const isMyCanvasOpen = useMemo(() => {
    if (!customerId) {
      return false;
    }
    return !!nodes.find(node => node.id === myCanvasId);
  }, [nodes, myCanvasId]);
  const myDropArea: Node<DropAreaData> | undefined = useMemo(() => {
    const myDropArea = nodes.find(node => node.id === myDropAreaId);
    return myDropArea;
  }, [nodes, myDropAreaId]);
  const isMyDropAreaOpen = !!myDropArea;

  const { data: customersInBoard = [] } = useQuery(
    [queryKeys.FETCH_BOARD_CUSTOMERS, boardCode],
    async () => {
      if (!boardCode || boardCode === BOARD_CODE_IF_NEW) {
        throw new Error('invalid value for board code');
      }
      return await fetchBoardCustomers(boardCode);
    },
  );

  const { data: boardMember } = useQuery(
    [
      queryKeys.FETCH_BOARD_CUSTOMER,
      boardCode,
      currentUser?.user?.customer?.id,
    ],
    () => {
      if (boardCode === BOARD_CODE_IF_NEW || typeof boardCode === 'undefined') {
        throw new Error('invalid board code');
      }
      if (!currentUser?.user?.customer?.id) {
        throw new Error('invlaid field for customer id');
      }
      return fetchBoardCustomer(currentUser.user.customer.id, boardCode);
    },
    {
      onSuccess: response => {
        if (response.id === undefined) {
          navigate('/my-board/personal');
        }
      },
    },
  );

  useEffect(() => {
    prevActiveCustomerIdsRef.current = activeCustomerIds;
  }, [activeCustomerIds]);

  useEffect(() => {
    return () => {
      clearActions();
      clearLocalNodes();
      clearLocalEdges();
      setActiveCard(undefined);
      setSocketCard(undefined);
      prevActiveCustomerIdsRef.current = [];
    };
  }, [boardCode]);

  useEffect(() => {
    if (recentlySelectedNodeIds.length < 1 && currentUser?.user?.customer?.id) {
      sendSelectedNodeIds(currentUser.user.customer.id, []);
    }
  }, [recentlySelectedNodeIds, currentUser, sendSelectedNodeIds]);

  useEffect(() => {
    if (!!boardMember && customersInBoard.length > 0) {
      if (!customersInBoard.some(customer => customer.id === boardMember.id)) {
        navigate('/my-board/personal');
      }
    }
  }, [boardMember, customersInBoard]);

  useQuery(
    [
      queryKeys.FETCH_BOARD_DIAGRAM,
      boardCode,
      boardData?.diagramFile,
      boardMember?.id,
    ],
    () => {
      if (
        boardCode === BOARD_CODE_IF_NEW ||
        typeof boardCode === 'undefined' ||
        typeof boardData?.diagramFile !== 'string'
      ) {
        throw new Error('invalid value for diagram url');
      }
      if (boardMember?.id === undefined) {
        throw new Error('Invalid board member');
      }
      return fetchBoardDiagram(boardData?.diagramFile);
    },
    {
      refetchOnMount: true,
      enabled:
        activeCustomerIds.length > 0 &&
        activeCustomerIds.length < 2 &&
        prevActiveCustomerIdsRef.current?.length === 0,
      onSuccess: response => {
        setNodes(
          () => includeWhitelistedNodes(nodes, response.nodes, []) || [],
        );
        setEdges(() => response.edges || []);
      },
    },
  );

  const { mutate: saveBoardFile } = useMutation(
    async () => {
      if (!boardCode) {
        throw new Error('invalid value for board code');
      }
      return saveBoard({
        boardCode,
        nodes,
        edges,
      });
    },
    {
      onSuccess: response => {
        setNotification({
          isOpen: true,
          message: response?.message || '',
          type: 'success',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
        queryClient.invalidateQueries([
          queryKeys.FETCH_BOARD_BY_CODE,
          boardCode,
        ]);
      },
      onError: err => {
        setNotification({
          isOpen: true,
          message: String(err),
          type: 'error',
          position: 'top-right',
          onClose: () => setNotification(null),
        });
      },
    },
  );

  useEffect(() => {
    if (
      boardCode !== BOARD_CODE_IF_NEW &&
      currentBoardCustomer &&
      currentBoardCustomer.roleName &&
      socket
    ) {
      joinBoard();
    }

    if (socket) {
      socket.on('OPEN_CARD_BOX', ({ flag }) => {
        socketToggleDrawer(flag);
      });

      socket.on('CARD_RANDOM_STATUS', ({ flag }) => {
        setIsBlocking(flag);
      });
    }
  }, [boardCode, currentBoardCustomer, socket]);

  useEffect(() => {
    const currentId = currentUser?.user?.customer?.id;
    if (
      activeCustomerIds.length > 1 &&
      currentId &&
      activeCustomerIds.some(item => item === currentId) &&
      nodes.length === 0 &&
      boardCode
    ) {
      sendLatestDiagramRequest(boardCode);
    }
  }, [activeCustomerIds, currentUser, nodes, boardCode]);

  useEffect(() => {
    if (!boardCode) {
      return;
    }
    socketInit(boardCode);
    return () => {
      disconnectSocket();
    };
  }, [boardCode, currentUser]);

  const updateAreaNodePosition = useCallback(
    (x: number, y: number, width: number, height: number) => {
      if (isMyCanvasOpen) {
        setMyCanvasNode({ width, height }, { x, y });
      }
      if (isMyDropAreaOpen) {
        setMyDropAreaNode({ x, y }, { width, height });
      }
    },
    [isMyCanvasOpen, isMyDropAreaOpen],
  );

  const clearRecentlySelectedNodes = useCallback(() => {
    setRecentlySelectedNodeIds([]);
  }, []);

  const selectNodes = useCallback(
    (nodeIds: string[]) => {
      if (nodeIds.length) {
        setDidSelectFromLasso(true);
        setNodes(currentNodes => {
          const selectableNodeIds: string[] = [];
          const updatedNodes = currentNodes.map(node => {
            const canSelect =
              !node.selected && nodeIds.includes(node.id) && !node.parentNode;

            if (canSelect) {
              selectableNodeIds.push(node.id);
            }
            return {
              ...node,
              selected: canSelect ? true : node.selected,
            };
          });

          const selectedNodeIdsState = customersSelectedNodeIds
            ? Object.values(customersSelectedNodeIds).flat()
            : [];
          const filteredSelectedNodeIds = selectableNodeIds.filter(
            (item: string) => selectedNodeIdsState.indexOf(item) < 0,
          );
          if (currentUser?.user?.customer?.id) {
            sendSelectedNodeIds(
              currentUser.user.customer.id,
              filteredSelectedNodeIds,
            );
          }
          setRecentlySelectedNodeIds(filteredSelectedNodeIds);
          return updatedNodes;
        });
      }
    },
    [setNodes, customersSelectedNodeIds, currentUser],
  );

  const handleLasso = useCallback(
    (e: OnSelectEnd) => {
      e.inputEvent.preventDefault();
      e.inputEvent.stopPropagation();
      if (myDropArea) {
        return;
      }
      const selectableIds = e.selected
        .map(el => el.getAttribute('data-id') || '')
        .filter(el => !!el);
      // NOTE: this has to be slightly delayed as react-flow dispatches a reset action upon multi select
      setTimeout(() => {
        selectNodes(selectableIds);
      }, 100);
    },
    [selectNodes, myDropArea],
  );

  const handleCopyNodes = useCallback(() => {
    setCopiedNodes(
      nodes.filter(
        node => recentlySelectedNodeIds.includes(node.id) && node.selected,
      ),
    );
  }, [nodes, recentlySelectedNodeIds]);

  const handleAddSticky = (
    backgroundColor: string,
    assignedPosition?: { x: number; y: number },
  ) => {
    if (flowRef?.current && reactFlowWrapper?.current) {
      const viewport = flowRef?.current?.getViewport();
      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
      const flowBoundsWidthNum = reactFlowBounds?.width || 0;
      const flowBoundsHeightNum = reactFlowBounds?.height || 0;
      const position = assignedPosition || {
        x:
          -viewport.x * (1 / viewport.zoom) +
          (flowBoundsWidthNum * (1 / viewport.zoom)) / 2 -
          50,
        y:
          -viewport.y * (1 / viewport.zoom) +
          (flowBoundsHeightNum * (1 / viewport.zoom)) / 2 -
          50,
      };
      addNewNode('sticky', position, {
        height: 100,
        width: 100,
        style: {
          backgroundColor,
        },
      });
    }
  };

  const handleAddShape = (
    type: ShapeData['type'],
    assignedPosition?: { x: number; y: number },
  ) => {
    if (flowRef?.current && reactFlowWrapper?.current) {
      const viewport = flowRef?.current?.getViewport();
      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
      const flowBoundsWidthNum = reactFlowBounds?.width || 0;
      const flowBoundsHeightNum = reactFlowBounds?.height || 0;
      const position = assignedPosition || {
        x:
          -viewport.x * (1 / viewport.zoom) +
          (flowBoundsWidthNum * (1 / viewport.zoom)) / 2 -
          50,
        y:
          -viewport.y * (1 / viewport.zoom) +
          (flowBoundsHeightNum * (1 / viewport.zoom)) / 2 -
          50,
      };
      addNewNode('shape', position, {
        type,
        width: 200,
        height: 200,
        style: {
          fill: 'white',
          strokeWidth: 4,
        },
      });
    }
  };

  const handleAddMedia = async (
    mediaUrl: string,
    assignedPosition?: { x: number; y: number },
  ) => {
    if (flowRef?.current && reactFlowWrapper?.current) {
      const viewport = flowRef?.current?.getViewport();
      const imageDimensions: {
        height: number;
        width: number;
      } = await new Promise(resolve => {
        const img = new Image();
        img.src = mediaUrl;
        img.onload = () => {
          resolve({
            height: img.height,
            width: img.width,
          });
        };
      });
      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
      const flowBoundsWidthNum = reactFlowBounds?.width || 0;
      const flowBoundsHeightNum = reactFlowBounds?.height || 0;
      const centerPosition = assignedPosition || {
        x:
          -viewport.x * (1 / viewport.zoom) +
          (flowBoundsWidthNum * (1 / viewport.zoom)) / 2 -
          50,
        y:
          -viewport.y * (1 / viewport.zoom) +
          (flowBoundsHeightNum * (1 / viewport.zoom)) / 2 -
          50,
      };
      addNewNode(
        'media',
        {
          x: centerPosition.x - 100,
          y: centerPosition.y - 100,
        },
        {
          mediaUrl,
          width: imageDimensions.width,
          height: imageDimensions.height,
        },
      );
    }
  };
  const onMouseMove = debounce((e: React.MouseEvent<HTMLDivElement>) => {
    const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
    const position = flowRef?.current?.project({
      x: e.clientX - (reactFlowBounds?.left || 0),
      y: e.clientY - (reactFlowBounds?.top || 0),
    });
    if (position !== undefined) {
      const posX = position?.x;
      const posY = position?.y;

      sendCursorPosition(
        posX,
        posY,
        currentUser?.user?.customer.id || 0,
        currentUser?.user.customer?.fullname || '',
      );
    }
  }, 10);

  const onDragOver = useCallback(event => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    event => {
      event.preventDefault();
      const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
      const type = event.dataTransfer.getData('node-type');
      const data = event.dataTransfer.getData('node-data');
      if (typeof type === 'undefined' || !type) {
        return;
      }
      const position = flowRef?.current?.project({
        x: event.clientX - (reactFlowBounds?.left || 0),
        y: event.clientY - (reactFlowBounds?.top || 0),
      });
      if (type === 'sticky') {
        handleAddSticky(data, position);
      }
      if (type === 'shape') {
        handleAddShape(data, position);
      }
      if (type === 'media') {
        handleAddMedia(data, position);
      }
    },
    [flowRef, setNodes],
  );
  const handleDragCheck = useCallback(
    (ev: React.MouseEvent<Element, MouseEvent>) => {
      if (flowRef.current) {
        const currentViewport = flowRef.current.getViewport();
        // NOTE: might have to adjust these if mobile
        const shouldMoveUp = ev.pageY < 100;
        const shouldMoveLeft = ev.pageX < 100;
        const shouldMoveRight = window.innerWidth - ev.pageX < 100;
        const shouldMoveDown = window.innerHeight - ev.pageY < 100;
        const offsetPixels = 10;

        flowRef.current.setViewport({
          x:
            currentViewport.x +
            ((shouldMoveRight && -offsetPixels) ||
              (shouldMoveLeft && offsetPixels) ||
              0),
          y:
            currentViewport.y +
            ((shouldMoveUp && offsetPixels) ||
              (shouldMoveDown && -offsetPixels) ||
              0),
          zoom: currentViewport.zoom,
        });
      }
    },
    [],
  );

  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#fff',
      width: '100%',
      orientation: 'landscape',
    },
    view: {
      width: '100%',
      height: '100%',
      padding: 0,
      backgroundColor: 'white',
    },
    image: {
      objectFit: 'cover',
    },
    viewer: {
      width: window.innerWidth, //the pdf viewer will take up all of the width and height
      height: window.innerHeight,
    },
  });

  const createPdf = (imageURI: string) => (
    <Document>
      <Page
        object-fit="fill"
        orientation="landscape"
        style={styles.page}
        size="A4">
        <View style={styles.view}>
          <PDFImage style={styles.image} src={imageURI} />
        </View>
      </Page>
    </Document>
  );

  const renderUrl = async (dataUrl: string) =>
    new Promise<string>(async resolve => {
      const blob = await ReactPDF.pdf(createPdf(dataUrl)).toBlob();
      const url = URL.createObjectURL(blob);
      if (url && url.length > 0) {
        resolve(url);
      }
    })
      .then(res => res)
      .catch(err => console.error(err));

  const downloadXML = async (dataUrl: string) =>
    new Promise<string>(async resolve => {
      const blob = new Blob([dataUrl], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      if (url && url.length > 0) {
        resolve(url);
      }
    })
      .then(res => res)
      .catch(err => console.error(err));

  const onMouseEnter = (entered: boolean) => {
    setCursorOnChatBox(entered);
  };

  const toggleDrawer = () => {
    if (isBlocking) return;
    setDrawerOpen(!isDrawerOpen);
    socket?.emit('REOPEN_CARD_BOX', {
      board_code: boardCode,
      flag: +!isDrawerOpen,
    });
    setChatBoxOpen(false);
  };

  const socketToggleDrawer = (flag: number) => {
    setDrawerOpen(flag === 1);
    setChatBoxOpen(false);
  };

  const onToggleChatBox = (toggle: boolean) => {
    setDrawerOpen(false);
    setChatBoxOpen(toggle);
  };

  return (
    <>
      <GlobalStyle />
      <StyledHeader
        boardCode={boardCode}
        currentBoardData={boardData}
        onClickCopy={handleCopyNodes}
        onClickPaste={handlePasteNodes}
        onClickUndo={undo}
        onClickRedo={redo}
        currentBoardCustomer={currentBoardCustomer}
        isFetchingCurrentBoardCustomer={isFetchingCurrentBoardCustomer}
        onClickKeep={saveBoardFile}
        onClickPng={() => {
          setTimeout(() => {
            if (
              flowRef &&
              flowRef?.current &&
              flowRef?.current?.getZoom() >= 0.5 // this is to prevent zooming out further which make the objects will go on to the right
            ) {
              flowRef?.current?.fitView({ minZoom: 0 });
            }
            const el = document.querySelector(
              '.react-flow__renderer',
            ) as HTMLElement;
            if (el) {
              toPng(el)
                .then(dataUrl => {
                  const link = document.createElement('a');
                  link.download = boardData?.name + '.png';
                  link.href = dataUrl;
                  link.click();
                })
                .catch(function(error) {
                  console.error('oops, something went wrong!', error);
                });
            }
          }, 1000);
        }}
        onClickPdf={() => {
          setTimeout(() => {
            if (
              flowRef &&
              flowRef?.current &&
              flowRef?.current?.getZoom() >= 0.5 // this is to prevent zooming out further which make the objects will go on to the right
            ) {
              flowRef?.current?.fitView({ minZoom: 0 });
            }
            const el = document.querySelector(
              '.react-flow__renderer',
            ) as HTMLElement;
            if (el) {
              toPng(el)
                .then(dataUrl => {
                  renderUrl(dataUrl)
                    .then(generatedUrl => {
                      if (generatedUrl) {
                        const aTag = document.createElement('a');
                        aTag.href = generatedUrl;
                        aTag.download = boardData?.name + '.pdf';
                        aTag.click();
                      } // else -> means something went wrong during pdf generation
                    })
                    .catch(err => console.error(err));
                })
                .catch(function(error) {
                  console.error('oops, something went wrong!', error);
                });
            }
          }, 1000);
        }}
        onClickXML={() => {
          if (boardData?.diagramFile) {
            const xmlToStr = xmljs.json2xml(
              JSON.stringify({
                data: {
                  nodes,
                  edges,
                },
              }),
              { compact: true },
            );
            const dataXML = `<?xml version="1.0"?>${xmlToStr}`;
            setTimeout(() => {
              downloadXML(dataXML)
                .then(generatedURL => {
                  if (generatedURL) {
                    const aTag = document.createElement('a');
                    aTag.href = generatedURL;
                    aTag.download = boardData?.name + '.xml';
                    aTag.click();
                  }
                })
                .catch(err => console.error(err));
            }, 1000);
          }
        }}
      />
      <Wrapper
        className={className}
        ref={reactFlowWrapper}
        id={DIAGRAM_CONTAINER_ID}
        tabIndex={1}
        onKeyDown={event => {
          if (event.currentTarget != event.target) return;
          const charCode = event.key.toLowerCase();
          if (charCode === 'backspace' || charCode === 'delete') {
            removeNodes(recentlySelectedNodeIds);
          } else if ((event.ctrlKey || event.metaKey) && charCode === 's') {
            event.preventDefault();
            event.stopPropagation();
            saveBoardFile();
          } else if ((event.ctrlKey || event.metaKey) && charCode === 'z') {
            event.stopPropagation();
            if (event.shiftKey) {
              redo();
            } else {
              undo();
            }
          } else if ((event.ctrlKey || event.metaKey) && charCode === 'c') {
            event.stopPropagation();
            handleCopyNodes();
          } else if ((event.ctrlKey || event.metaKey) && charCode === 'v') {
            event.stopPropagation();
            handlePasteNodes();
          }
        }}>
        {allowElementsSelect &&
          currentlySelectedNodes.length === 0 &&
          myDropArea?.data?.objectType !== 'line' &&
          !isMyCanvasOpen &&
          !isCursorOnChatBox && (
            <Selecto
              container={reactFlowWrapper.current}
              selectableTargets={['.react-flow__node']}
              keyContainer={reactFlowWrapper.current}
              onSelectEnd={handleLasso}
              hitRate="1px"
            />
          )}
        <StyledReactFlow
          className={!allowElementsSelect ? 'disable-nodes' : undefined}
          onMoveStart={e => {
            if (e && e.target && !allowElementsSelect) {
              const getElementClass = e.target as HTMLElement; // get the specfic class on the body container
              getElementClass.style.cursor = 'grabbing'; // this is to shift the style cursor to grabbing while dragging the whole chart
            }
          }}
          onDrop={onDrop}
          onDragOver={onDragOver}
          onMouseMove={onMouseMove}
          onPaneClick={() => {
            setDidSelectFromLasso(false);
            clearRecentlySelectedNodes();
          }}
          onMoveEnd={e => {
            if (e && e.target) {
              const getElementClass = e.target as HTMLElement; // get the specfic class on the body container
              getElementClass.style.cursor = 'unset'; // this is to shift the style cursor to grabbing while dragging the whole chart
            }
            const position = flowRef?.current?.project({
              x: 0,
              y: 0,
            });
            if (!position) {
              console.error('unable to compute position');
              return;
            }
            const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
            updateAreaNodePosition(
              position.x,
              position.y,
              reactFlowBounds?.width || 0,
              reactFlowBounds?.height || 0,
            );
          }}
          panOnScroll
          panOnDrag={!allowElementsSelect}
          elementsSelectable={allowElementsSelect}
          nodesDraggable={allowElementsSelect}
          connectionMode={ConnectionMode.Loose}
          nodeTypes={customNodeTypes}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          edgeTypes={customEdgeTypes}
          nodes={nodes}
          edges={edges}
          onNodesChange={nodeChanges => {
            const isSelecting =
              nodeChanges.filter(nodeChange => nodeChange.type === 'select')
                .length === nodeChanges.length;
            if (isSelecting) {
              onNodesChange(
                nodeChanges.filter(
                  nodeChange =>
                    nodeChange.type === 'select' &&
                    (recentlySelectedNodeIds.includes(nodeChange.id) ||
                      nodeChange.selected),
                ),
              );
              return;
            }
            const isDragging =
              nodeChanges.filter(nodeChange => nodeChange.type === 'position')
                .length === nodeChanges.length;

            if (isDragging) {
              onNodesChange(
                nodeChanges.filter(
                  nodeChange =>
                    nodeChange.type === 'position' &&
                    recentlySelectedNodeIds.includes(nodeChange.id),
                ),
              );
              return;
            }
            onNodesChange(nodeChanges);
          }}
          onEdgesChange={onEdgesChange}
          onNodeDrag={ev => {
            handleDragCheck(ev);
          }}
          onNodeDragStart={(ev, draggedNode) => {
            const newSelectedNodes = didSelectFromLasso
              ? [...recentlySelectedNodeIds, draggedNode.id]
              : [draggedNode.id];

            const selectedNodeIdsState = customersSelectedNodeIds
              ? Object.values(customersSelectedNodeIds).flat()
              : [];
            const filteredSelectedNodeIds = newSelectedNodes.filter(
              (item: string) => selectedNodeIdsState.indexOf(item) < 0,
            );
            if (currentUser?.user?.customer?.id) {
              sendSelectedNodeIds(
                currentUser.user.customer.id,
                filteredSelectedNodeIds,
              );
            }

            setRecentlySelectedNodeIds(filteredSelectedNodeIds);
            onDragNodesStart(newSelectedNodes);
          }}
          onNodeDragStop={(ev, draggedNode) => {
            onDragNodesEnd([...recentlySelectedNodeIds, draggedNode.id]);
            const shouldCheckRemoveLineNode =
              draggedNode.type === 'line' &&
              draggedNode.data.target &&
              draggedNode.data.source;
            // NOTE: if dragged object is line node between
            if (shouldCheckRemoveLineNode) {
              const a = findNode(draggedNode.data.source);
              const b = findNode(draggedNode.id);
              const c = findNode(draggedNode.data.target);
              if (
                a?.positionAbsolute &&
                b?.positionAbsolute &&
                c?.positionAbsolute
              ) {
                const shouldRemoveCenter = areCollinear(
                  a.positionAbsolute,
                  b.positionAbsolute,
                  c.positionAbsolute,
                  2,
                );
                if (shouldRemoveCenter) {
                  removeLineNodePoint(draggedNode.id);
                  forceDiagramUpdate();
                  return;
                }
              }
            }

            const bindThreshold = 50;
            // NOTE: if already connected to a parent node
            if (draggedNode?.parentNode) {
              const parentNode = findNode(draggedNode.parentNode);
              const posX = draggedNode.position.x;
              const posY = draggedNode.position.y;
              const shouldUnbind =
                posX >= (parentNode?.data?.width || 0) + bindThreshold ||
                posY >= (parentNode?.data?.height || 0) + bindThreshold ||
                posX <= -bindThreshold ||
                posY <= -bindThreshold;
              if (shouldUnbind && parentNode) {
                removeParentNodes([parentNode]);
                forceDiagramUpdate();
                return;
              }
            }

            const shouldBind =
              draggedNode.type === 'line' &&
              (!draggedNode.data?.target || !draggedNode.data?.source) &&
              !draggedNode.parentNode;
            const nearestNode = getOverlappingNode(draggedNode);
            // NOTE: connect a line node to a non line node
            if (shouldBind && nearestNode?.id) {
              setParentNode(draggedNode, nearestNode);
              forceDiagramUpdate();
              return;
            }

            forceDiagramUpdate();
          }}
          onNodesDelete={deletedNodes => {
            const deletedParentNodes = deletedNodes.filter(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              node => node.isParent,
            );
            removeParentNodes(deletedParentNodes);
          }}
          onInit={ref => {
            flowRef.current = ref;
          }}
          deleteKeyCode={null}
          onEdgesDelete={deletedEdges => {
            const edgeGroupIds = deletedEdges
              .map(edge => edge.data.groupId)
              .filter(nodeId => nodeId);
            removeLineNodeGroup(edgeGroupIds);
          }}
          onNodeClick={(ev, clickedNode) => {
            const selectedNodeIdsState = customersSelectedNodeIds
              ? Object.values(customersSelectedNodeIds).flat()
              : [];
            if (
              !selectedNodeIdsState.includes(clickedNode.id) &&
              currentUser?.user?.customer?.id
            ) {
              sendSelectedNodeIds(currentUser.user.customer.id, [
                clickedNode.id,
              ]);
              setRecentlySelectedNodeIds([clickedNode.id]);
            }
            setDidSelectFromLasso(false);
          }}>
          <DiagramCursors />

          <StyledDiagramControl
            className="react-flow__controls"
            onClickZoomIn={() => {
              if (flowRef?.current) {
                flowRef.current.zoomIn();
              }
            }}
            onClickZoomOut={() => {
              if (flowRef?.current) {
                flowRef.current.zoomOut();
              }
            }}
            onClickRedo={redo}
            onClickUndo={undo}
          />
          <ChatBox
            onMouseEnter={onMouseEnter}
            boardName={boardData?.name}
            boardCode={boardCode}
            onToggleChatBox={onToggleChatBox}
            isOpen={isChatBoxOpen}
          />
          <DiagramToolbarContainer>
            <DiagramToolbar
              setIsMediaPickerOpen={setIsMediaPickerOpen}
              cursorState={allowElementsSelect ? 'pointer' : 'grab'}
              selectedObjects={{
                cursor: !myDropArea && !isMyCanvasOpen && !isMediaPickerOpen,
                shape: myDropArea?.data?.objectType === 'shape',
                sticky: myDropArea?.data?.objectType === 'sticky',
                text: myDropArea?.data?.objectType === 'label',
                arrow: myDropArea?.data?.objectType === 'line',
                stroke: isMyCanvasOpen,
                media: isMediaPickerOpen,
              }}
              onClickCursor={() => {
                removeMyAreaNodes();
                clearRecentlySelectedNodes();
                setAllowElementsSelect(!allowElementsSelect);
              }}
              onClickShape={() => {
                removeMyAreaNodes();
                clearRecentlySelectedNodes();
                setAllowElementsSelect(true);
              }}
              onSelectShape={e => {
                if (flowRef?.current && reactFlowWrapper?.current) {
                  const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
                  const position = flowRef?.current?.project({
                    x: 0,
                    y: 0,
                  });
                  if (!position) {
                    console.error('unable to compute position');
                    return;
                  }
                  setMyDropAreaNode(position, {
                    width: reactFlowBounds?.width || 0,
                    height: reactFlowBounds?.height || 0,
                    objectType: 'shape',
                    shapeType: e,
                  });
                }
              }}
              onClickSticky={() => {
                removeMyAreaNodes();
                clearRecentlySelectedNodes();
                setAllowElementsSelect(true);
              }}
              onSelectSticky={color => {
                if (flowRef?.current && reactFlowWrapper?.current) {
                  const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
                  const position = flowRef?.current?.project({
                    x: 0,
                    y: 0,
                  });
                  if (!position) {
                    console.error('unable to compute position');
                    return;
                  }
                  setMyDropAreaNode(position, {
                    width: reactFlowBounds?.width || 0,
                    height: reactFlowBounds?.height || 0,
                    objectType: 'sticky',
                    stickyColor: color,
                  });
                }
              }}
              onClickText={() => {
                setAllowElementsSelect(true);
                clearRecentlySelectedNodes();
                if (flowRef?.current && reactFlowWrapper?.current) {
                  const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
                  const position = flowRef?.current?.project({
                    x: 0,
                    y: 0,
                  });
                  if (!position) {
                    console.error('unable to compute position');
                    return;
                  }
                  setMyDropAreaNode(position, {
                    width: reactFlowBounds?.width || 0,
                    height: reactFlowBounds?.height || 0,
                    objectType: 'label',
                  });
                }
              }}
              onClickArrow={() => {
                setAllowElementsSelect(true);
                clearRecentlySelectedNodes();
                if (flowRef?.current && reactFlowWrapper?.current) {
                  const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
                  const position = flowRef?.current?.project({
                    x: 0,
                    y: 0,
                  });
                  if (!position) {
                    console.error('unable to compute position');
                    return;
                  }

                  setMyDropAreaNode(
                    position,
                    {
                      width: reactFlowBounds?.width || 0,
                      height: reactFlowBounds?.height || 0,
                      objectType: 'line',
                    },
                    latestNodes => {
                      return latestNodes.filter(node => node.id !== myCanvasId);
                    },
                  );
                }
              }}
              onClickPen={() => {
                setAllowElementsSelect(true);
              }}
              onSelectPen={(strokeWidth, strokeColor) => {
                clearRecentlySelectedNodes();
                const reactFlowBounds = reactFlowWrapper?.current?.getBoundingClientRect();
                const position = flowRef?.current?.project({
                  x: 0,
                  y: 0,
                });
                const zoomLevel = flowRef?.current?.getZoom() || 1;
                if (!position) {
                  console.error('unable to compute position');
                  return;
                }
                const dimensions = {
                  width: reactFlowBounds?.width || 0,
                  height: reactFlowBounds?.height || 0,
                };
                const strokeProperties = {
                  strokeWidth: strokeWidth * (1 / zoomLevel),
                  strokeColor: strokeColor,
                };
                setMyCanvasNode(dimensions, position, strokeProperties);
              }}
              onSelectFrame={handleAddMedia}
              onClickFrame={() => {
                setAllowElementsSelect(true);
                clearRecentlySelectedNodes();
                removeMyAreaNodes();
              }}
            />
          </DiagramToolbarContainer>
          <DiagramMembersContainer
            style={isMyCanvasOpen ? { pointerEvents: 'none' } : undefined}>
            <MemberList boardCode={boardCode} />
          </DiagramMembersContainer>
          <GameCardDrawer
            toggleDrawer={toggleDrawer}
            socketToggleDrawer={socketToggleDrawer}
            open={isDrawerOpen}
            boardCode={boardCode}
            currentBoardCustomer={currentBoardCustomer}
            isBlocking={isBlocking}
          />
        </StyledReactFlow>
      </Wrapper>
      {notification ? <Toast {...notification} /> : null}
    </>
  );
};

export default Component;
