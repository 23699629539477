import React, { useState } from 'react';
import styled from 'styled-components';
import Box, { BoxProps } from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import theme from '../../../constants/themes';
import { useTranslation } from 'react-i18next';

const StyledCard = styled(Box)`
  background-color: ${theme.colors.gameCardBaseColor};
  border: 1px solid ${theme.colors.gameCardBorderColor};
  border-radius: 10px;
  display: flex;
  position: relative;
  cursor: pointer;
`;

const StyledCardBody = styled(Box)`
  background-color: ${theme.colors.white};
  border: 1px solid #cea400;
  border-radius: 10px;
  flex: 1;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledLabel = styled.span`
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: ${theme.colors.inputBorder};
`;

const ImageWrapper = styled(Box)`
  overflow: hidden;
  position: relative;
`;

const StyledImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledContent = styled(Box)`
  height: 180px;
  font-family: ${theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${theme.colors.textColor};
  padding: 10px;
  white-space: pre-wrap;
`;

const StyledButton = styled(Box)`
  font-family: ${theme.fonts.secondary};
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.02em;
  color: ${theme.colors.white};

  background-color: ${theme.colors.otherHintRoundBtn};
  height: 80px;
  width: 83px;
  border: none;
  border-radius: 100px;
  position: absolute;
  top: -30px;
  left: -6px;
  cursor: pointer;
  &:active {
    background-color: ${theme.colors.otherHintRoundBtnActive};
  }
`;

export type Props = {
  isLoading?: boolean;
  isScaled?: boolean;
  displayPhoto?: string;
  content?: string;
  onClickHint?: (e: any) => void;
} & BoxProps;

const Component = (props: Props): React.ReactElement => {
  const { t } = useTranslation();
  const [hasOtherHint, setOtherHint] = useState<boolean>(true);
  const {
    isScaled,
    displayPhoto,
    onClickHint,
    content,
    isLoading,
    ...boxProps
  } = props;

  const onClickCard = () => !isLoading && setOtherHint(!hasOtherHint);

  return isScaled ? (
    <StyledCard onClick={onClickCard} width={270} height={418} {...boxProps}>
      <StyledCardBody justifyContent="normal" paddingTop="28px">
        <StyledLabel>{t('hint')}</StyledLabel>
        {isLoading ? (
          <>
            <Skeleton
              sx={{
                borderRadius: 2,
              }}
              variant="rectangular"
              width={217}
              height={125}
            />
            <Typography paddingTop={2} component="div" variant="caption">
              {Array(5)
                .fill(null)
                .map((_, index) => (
                  <Skeleton key={index} width="220px" />
                ))}
              <Skeleton width="100px" />
            </Typography>
          </>
        ) : (
          <>
            <ImageWrapper width={217} height={138}>
              <StyledImage src={displayPhoto} alt="Card Image" />
            </ImageWrapper>
            <StyledContent component="p">{content}</StyledContent>
          </>
        )}

        {!isLoading && hasOtherHint && (
          <StyledButton
            onClick={e => {
              e.stopPropagation();
              onClickHint && onClickHint(e);
            }}
            component="button">
            {t('otherHint')}
          </StyledButton>
        )}
      </StyledCardBody>
    </StyledCard>
  ) : (
    <StyledCard width={100} height={153} {...boxProps}>
      <StyledCardBody>
        <StyledLabel>{t('hint')}</StyledLabel>
        {isLoading ? (
          <Skeleton
            sx={{
              borderRadius: 1,
            }}
            variant="rectangular"
            width={80}
            height={52}
          />
        ) : (
          <ImageWrapper width={84} height={52}>
            <StyledImage src={displayPhoto} alt="Card Image" />
          </ImageWrapper>
        )}
      </StyledCardBody>
    </StyledCard>
  );
};

export default Component;
