import React, { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import { motion, Variants, AnimatePresence } from 'framer-motion';
import { YellowCard } from '../../atoms/YellowCard';
import { HintCard } from '../../atoms/HintCard';
import { HintBulb } from '../../atoms/Icons';
import theme from '../../../constants/themes';
import { useGlobalState } from '../../../hooks/global';
import { useSocketHooks } from '../../../hooks/socket';
import { useBoardHooks } from '../../../hooks/board';
import { BOARD_CODE_IF_NEW } from '../../pages/BoardEditor/Component';
import { BoardCustomers } from '../../../domain/entities/board';

const StyledBulbPuller = styled(Box)`
  background-color: ${theme.colors.secondary};
`;

const StyledHintBulbWraper = styled.div`
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: center;
`;

const YellowCardMotionWrapper = styled(motion.div)`
  height: 128.29px;
  width: 102.3px;
  border-radius: 10px;
  position: absolute;
  cursor: pointer;
  bottom: 3px;
  left: -52.5px;
`;

const YellowCardMotion = styled(motion.div)`
  height: 128.29px;
  width: 102.3px;
  border-radius: 10px;
  position: absolute;
  backface-visibility: hidden;
`;

const StyledCardBody = styled(Box)`
  background-color: #e3b70a;
  border-radius: 10px;
  flex: 1;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledGameCardContainer = styled(Box)`
  background-color: ${theme.colors.secondary};
  width: 287px;
  height: 510px;
  z-index: 1200;
  padding: 15px;
`;

const StyledGameCardBody = styled(Box)`
  background-color: ${theme.colors.gameCardSubWrapperColor};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledYellowCardWrapper = styled(Box)`
  flex: 1;
  display: flex;
  justify-content: center;
  position: relative;
`;

const StyledYellowCardDeck = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: end;
  margin-bottom: 15px;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const StyledHintCardWrapper = styled(Box)`
  flex: 1;
  display: flex;
  align-items: end;
  justify-content: center;
`;

const StyledScaledCardMotion = styled(motion.div)`
  position: absolute;
  bottom: 18px;
  backface-visibility: hidden;
`;

const FlippedCardMotion = styled(motion.div)`
  position: absolute;
  top: 68px;
`;

const UnflipCardMotion = styled(motion.div)`
  position: absolute;
  bottom: 0;
  backface-visibility: hidden;
`;

const YellowCardOpacity = styled(YellowCard)`
  opacity: 0.3;
  cursor: default;
`;

const HintCardOpacity = styled(HintCard)`
  opacity: 0.3;
  cursor: default;
`;

const HintBulbOpacity = styled(HintBulb)`
  opacity: 0.3;
  cursor: default;
`;

type Props = {
  toggleDrawer: () => void;
  socketToggleDrawer: (flag: number) => void;
  open: boolean;
  boardCode?: string;
  currentBoardCustomer?: BoardCustomers;
  isBlocking?: boolean;
};

const Component = ({
  toggleDrawer,
  socketToggleDrawer,
  open,
  boardCode,
  currentBoardCustomer,
  isBlocking,
}: Props): React.ReactElement => {
  const {
    useCurrentCards: {
      activeCard,
      currentFlippedCards,
      socketCard,
      setActiveCard,
      setSocketCard,
      setCurrentFlippedCards,
    },
    useCurrentUser,
  } = useGlobalState();
  const { currentUser } = useCurrentUser;
  const { useBoardSocket } = useBoardHooks();
  const { joinBoard } = useBoardSocket();
  const [isFlipping, setIsFlipping] = useState<boolean>(false);
  const [isClick, setIsClick] = useState<boolean>(false);
  const [cardCount, setCardCount] = useState<number>(3);
  const [maxCard, setMaxCard] = useState<number>(3);
  const [cycle, setCycle] = useState<
    'flip' | 'scaled' | 'hidden' | 'scaledFlipped' | 'shuffle'
  >('hidden');
  const { useSocket } = useSocketHooks();
  const { socketInit, socket } = useSocket;

  const randomRotateRepeatValue = [2, 2, 3];
  const randomScaledDelayValue = [2, 2, 2.5];
  const randomFlipValueIndex = Math.floor(Math.random() * 3);

  const flipMotion = {
    y: -180,
    scale: 2.6,
    zIndex: 1,
    boxShadow: '0px 21px 1px rgba(128, 128, 128, 0.24)',
    transition: {
      ease: 'easeInOut',
      rotateY: {
        delay: 0.3,
        duration: 0.6,
        repeat: randomRotateRepeatValue[randomFlipValueIndex],
      },
      scale: {
        delay: randomScaledDelayValue[randomFlipValueIndex],
      },
      y: {
        duration: 0.3,
      },
      boxShadow: {
        duration: 0.3,
      },
    },
  };

  const cardVariant: Variants = {
    flip: i => ({
      rotateY: i === 'front' ? [0, 180, 0] : [180, 0, 180],
      ...flipMotion,
    }),
    scaled: i => ({
      zIndex: 1,
      rotateY: i === 'front' ? [180, 0] : [0, 180],
      scale: 1,
      opacity: 1,
      y: 18,
      transition: {
        scale: {
          duration: 0.2,
        },
      },
    }),
    scaledFlipped: {
      zIndex: 1,
      scale: 1,
      opacity: 1,
      y: -8,
      transition: {
        scale: {
          duration: 0.5,
        },
        opacity: {
          duration: 0.3,
        },
      },
    },
    shuffle: i => ({
      y: 0,
      opacity: 1,
      zIndex: i.zIndex,
      rotateX: i.type === 'front' ? [0, 180] : [180, 0],
      transition: {
        y: {
          duration: i.transValue + 0.1,
          delay: i.transValue,
        },
        rotateX: {
          duration: i.transValue + 0.1,
          delay: i.transValue,
        },
      },
    }),
    hidden: {},
  };

  useEffect(() => {
    socketInit(boardCode);
  }, []);

  useEffect(() => {
    if (activeCard) {
      setCurrentFlippedCards([...currentFlippedCards, activeCard]);
    }
  }, [activeCard]);

  useEffect(() => {
    if (
      boardCode !== BOARD_CODE_IF_NEW &&
      currentBoardCustomer &&
      currentBoardCustomer.roleName &&
      socket
    ) {
      joinBoard();
    }

    if (socket) {
      socket.on('OPEN_CARD_DETAIL', ({ flag, activeCard }) => {
        if (!open) {
          socketToggleDrawer(1);
        }

        if (flag === 1) {
          onSocketActiveCardClick();
          setActiveCard({
            cardName: activeCard?.cardName,
            cardImage: activeCard?.cardImage,
            cardContent: activeCard?.cardContent,
          });
        } else {
          handleOnSocketClickHint();
        }
      });

      socket.on('SHUFFLE_CARDS', () => {
        setIsFlipping(false);
        setActiveCard(undefined);
        setCycle('shuffle');
      });
      socket.on('CARD_RANDOM_STATUS', ({ flag }) => {
        if (flag) {
          setIsFlipping(true);
          setCycle('flip');
        } else {
          setIsFlipping(false);
        }
      });
      socket.on('CARD_RANDOM', ({ name, image, content, available_cards }) => {
        setSocketCard({
          cardName: name,
          cardImage: image,
          cardContent: content,
        });
        setIsClick(prev => {
          if (!prev) {
            onSocketActiveCardClick();
          }

          return false;
        });
        if (!open) {
          socketToggleDrawer(1);
        }
        setCardCount(available_cards);

        setMaxCard(prev => {
          if (prev > available_cards) {
            return prev;
          }

          return available_cards;
        });
      });
    }
  }, [socket]);

  const handleToggleDrawer = () => {
    toggleDrawer();
    setCycle('hidden');
  };

  const handleFlip = (e: any) => {
    if (e.detail > 1) return;
    if (isBlocking) return;
    if (!isFlipping && cardCount > 0) {
      setCycle('flip');
      setIsFlipping(true);
    }

    if (!isFlipping && cardCount === 0) {
      setActiveCard(undefined);
      setCycle('shuffle');
    }

    socket?.emit('NEW_CARD_RANDOM', {
      board_code: boardCode,
      token: currentUser?.user?.token,
    });
  };

  const handleOnClickHint = () => {
    if (isBlocking) return;
    socket?.emit('REOPEN_CARD_DETAIL', {
      board_code: boardCode,
      flag: 0,
      activeCard: activeCard,
    });
    setCycle('hidden');
  };

  const handleOnSocketClickHint = () => {
    setCycle('hidden');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdate = (latest: any) => {
    if (latest.scale > 1.5) {
      setCycle('scaled');
    } else {
      if (cycle === 'flip') setCycle('flip');
    }
  };

  const onExitComplete = () => {
    setIsFlipping(false);
    setActiveCard(socketCard);
  };

  const onActiveCardClick = () => {
    if (isBlocking) return;
    setCycle('scaledFlipped');
    socket?.emit('REOPEN_CARD_DETAIL', {
      board_code: boardCode,
      flag: 1,
      activeCard: activeCard,
    });
  };

  const onSocketActiveCardClick = () => {
    setCycle('scaledFlipped');
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onUpdateShuffle = (latest: any) => {
    if (latest.y === 0) {
      setTimeout(() => {
        setCycle('hidden');
        setCardCount(maxCard);
      }, 500);
    }
  };

  const shuffleCards = () => {
    const firstTenFlippedCards = currentFlippedCards.slice(0, 10);
    const shuffleTransitionValue = firstTenFlippedCards
      .map((_, index) => (index + 1) * 0.2 + 0.2)
      .reverse();

    return firstTenFlippedCards.map((value, index) => {
      const onUpdateProps =
        index + 1 === firstTenFlippedCards.length
          ? { onUpdate: onUpdateShuffle }
          : {};

      return (
        <Fragment key={index}>
          <UnflipCardMotion
            variants={cardVariant}
            animate="shuffle"
            custom={{
              type: 'back',
              transValue: shuffleTransitionValue[index],
              zIndex: firstTenFlippedCards.length - index,
            }}
            initial={{ y: -265, opacity: 0, zIndex: 0 }}
            exit={{
              opacity: 0,
              transition: {
                opacity: {
                  duration: (index + 1) * 0.2 + 0.3,
                  delay: 1,
                },
              },
            }}>
            <YellowCard />
          </UnflipCardMotion>
          <UnflipCardMotion
            {...onUpdateProps}
            variants={cardVariant}
            animate="shuffle"
            custom={{
              type: 'front',
              transValue: shuffleTransitionValue[index],
            }}
            initial={{ y: -265, opacity: 0, zIndex: 0 }}>
            <HintCard displayPhoto={value?.cardImage} />
          </UnflipCardMotion>
        </Fragment>
      );
    });
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={handleToggleDrawer}
      onOpen={toggleDrawer}
      swipeAreaWidth={80}
      SwipeAreaProps={{
        onClick: toggleDrawer,
        style: {
          height: 80,
          top: `50%`,
          transform: `translateY(-50%)`,
          borderTopLeftRadius: 50,
          borderBottomLeftRadius: 50,
          cursor: 'pointer',
        },
      }}
      BackdropProps={{ invisible: true, sx: { zIndex: -2 } }}
      PaperProps={{
        sx: {
          height: 510,
          marginTop: 'calc((100vh - 510px) / 2)',
          overflow: 'visible',
        },
        elevation: 0,
      }}
      hideBackdrop
      disableBackdropTransition={true}
      disableSwipeToOpen={false}
      disableEnforceFocus
      ModalProps={{
        keepMounted: true,
        disableEscapeKeyDown: true,
        sx: {
          height: 0,
          width: 0,
        },
      }}>
      <StyledBulbPuller
        sx={{
          position: 'absolute',
          left: -80,
          borderTopLeftRadius: 50,
          borderBottomLeftRadius: 50,
          top: `50%`,
          bottom: 0,
          width: 80,
          height: 80,
          visibility: 'visible',
          transform: `translateY(-50%)`,
          cursor: 'pointer',
        }}
        onClick={toggleDrawer}>
        <StyledHintBulbWraper>
          {isBlocking ? <HintBulbOpacity /> : <HintBulb />}
        </StyledHintBulbWraper>
      </StyledBulbPuller>
      <StyledGameCardContainer>
        <StyledGameCardBody>
          <StyledHintCardWrapper>
            {activeCard &&
              (isBlocking ? (
                <HintCardOpacity
                  onClick={onActiveCardClick}
                  sx={{ marginBottom: '40px' }}
                  displayPhoto={activeCard.cardImage}
                  content={activeCard.cardContent}
                />
              ) : (
                <HintCard
                  onClick={onActiveCardClick}
                  sx={{ marginBottom: '40px' }}
                  displayPhoto={activeCard.cardImage}
                  content={activeCard.cardContent}
                />
              ))}
            <AnimatePresence>
              {cycle === 'scaledFlipped' && (
                <FlippedCardMotion
                  onTap={handleOnClickHint}
                  variants={cardVariant}
                  animate="scaledFlipped"
                  initial={{ y: -140, scale: 0.34, opacity: 0 }}
                  exit={{
                    opacity: 0,
                    y: -140,
                    scale: 0.31,
                    transition: {
                      opacity: {
                        duration: 0.5,
                        delay: 0.4,
                      },
                      y: {
                        duration: 0.5,
                      },
                      scale: {
                        duration: 0.5,
                      },
                    },
                  }}>
                  <HintCard
                    onClickHint={e => {
                      handleFlip(e);
                    }}
                    displayPhoto={activeCard?.cardImage}
                    content={activeCard?.cardContent}
                    isScaled
                  />
                </FlippedCardMotion>
              )}
            </AnimatePresence>
          </StyledHintCardWrapper>
          <StyledYellowCardWrapper>
            <StyledYellowCardDeck>
              {cardCount > 1 &&
                (isBlocking ? (
                  <>
                    <YellowCardOpacity
                      sx={{ position: 'absolute', left: -50.7, bottom: -1 }}
                      boxShadow={`4px 4px 4px rgba(0, 0, 0, 0.25)`}
                    />
                    <YellowCardOpacity
                      sx={{ position: 'absolute', left: -48.3, bottom: -2.9 }}
                    />
                  </>
                ) : (
                  <>
                    <YellowCard
                      sx={{ position: 'absolute', left: -50.7, bottom: -1 }}
                      boxShadow={`4px 4px 4px rgba(0, 0, 0, 0.25)`}
                    />
                    <YellowCard
                      sx={{ position: 'absolute', left: -48.3, bottom: -2.9 }}
                    />
                  </>
                ))}
              {cardCount > 0 &&
                (isBlocking ? (
                  <YellowCardOpacity
                    sx={{ position: 'absolute' }}
                    boxShadow={
                      cardCount === 1
                        ? `4px 4px 4px rgba(0, 0, 0, 0.25)`
                        : 'none'
                    }
                  />
                ) : (
                  <YellowCard
                    sx={{ position: 'absolute' }}
                    boxShadow={
                      cardCount === 0
                        ? `4px 4px 4px rgba(0, 0, 0, 0.25)`
                        : 'none'
                    }
                  />
                ))}
              <YellowCardMotionWrapper
                onClick={e => {
                  handleFlip(e);
                }}>
                <AnimatePresence onExitComplete={onExitComplete}>
                  {cycle === 'flip' && (
                    <>
                      <YellowCardMotion
                        custom="back"
                        variants={cardVariant}
                        animate={'flip'}
                        initial={{ y: 0 }}>
                        <YellowCard display="flex">
                          <StyledCardBody />
                        </YellowCard>
                      </YellowCardMotion>
                      <YellowCardMotion
                        custom="front"
                        onUpdate={onUpdate}
                        variants={cardVariant}
                        animate={'flip'}
                        initial={{ y: 0 }}
                        exit={{
                          opacity: 0.1,
                          y: -180,
                          transition: {
                            opacity: {
                              duration: 0.4,
                            },
                          },
                        }}>
                        <YellowCard />
                      </YellowCardMotion>
                    </>
                  )}
                </AnimatePresence>
              </YellowCardMotionWrapper>
              <AnimatePresence>
                {cycle === 'scaled' && (
                  <>
                    <StyledScaledCardMotion
                      custom="front"
                      onTap={handleOnClickHint}
                      variants={cardVariant}
                      animate="scaled"
                      initial={{ y: -28, scale: 0.35, opacity: 0 }}
                      exit={{
                        opacity: 0,
                        y: -115,
                        scale: 0.31,
                        transition: {
                          opacity: {
                            duration: 0.5,
                            delay: 0.5,
                          },
                          y: {
                            duration: 0.5,
                          },
                          scale: {
                            duration: 0.5,
                          },
                        },
                        zIndex: 0,
                      }}>
                      <HintCard
                        onClickHint={e => {
                          handleFlip(e);
                        }}
                        displayPhoto={activeCard?.cardImage}
                        isScaled
                        content={activeCard?.cardContent}
                      />
                    </StyledScaledCardMotion>
                    <StyledScaledCardMotion
                      variants={cardVariant}
                      animate="scaled"
                      custom="back"
                      initial={{ y: -28, scale: 0.35, opacity: 0 }}
                      exit={{
                        opacity: 0,
                        y: -110,
                        scale: 0.31,
                        transition: {
                          opacity: {
                            duration: 0,
                          },
                          y: {
                            duration: 0.5,
                          },
                          scale: {
                            duration: 0,
                          },
                        },
                        zIndex: 0,
                      }}>
                      <YellowCard width={276} height={407} />
                    </StyledScaledCardMotion>
                  </>
                )}
              </AnimatePresence>
              <AnimatePresence>
                {cycle === 'shuffle' && shuffleCards()}
              </AnimatePresence>
            </StyledYellowCardDeck>
          </StyledYellowCardWrapper>
        </StyledGameCardBody>
      </StyledGameCardContainer>
    </SwipeableDrawer>
  );
};

export default Component;
